<template>
  <b-card>
    <b-row>
      <b-col>
        <b-form-input v-model="vendorSearch" placeholder="Search by vendor name" type="search" debounce="500">
        </b-form-input>
      </b-col>
      <b-col md="3">
        <b-form-input v-model="statusSearch" placeholder="Search by status" type="search" debounce="500">
        </b-form-input>
      </b-col>
    </b-row>
    <b-row class="p-1"></b-row>
    <b-row>
      <b-col>
        <b-table sticky-header="600px" responsive="xl" :busy="isLoading" :fields="fields" :items="items" show-empty hover
          caption-top>
          <template #head()="row">
            <div class="text-nowrap">
              {{ row.label }}
            </div>
          </template>
          <template #table-caption>
            <div class="text-right">
              <span>Count: {{ totalRows }}</span>
            </div>
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner small class="align-middle"> </b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(is_active)="row">
            <b-form-checkbox :checked="row.item.is_active" :value=true :unchecked-value=false
              @change="onUpdate($event, row.item, 'is_active')">
            </b-form-checkbox>
          </template>
          <template #cell(status)="row">
            <div class="d-flex">
              <b-form-input :value="row.item.status" @change="onUpdate($event, row.item, 'status')"
                :disabled="!(isEditableStatus == row.item.id)">
              </b-form-input>
              <b-button @click="isEditableStatus = row.item.id">
                <i class="fa fa-edit"></i>
              </b-button>
            </div>
          </template>
          <template #cell(single)="row">
            <b-form-checkbox :checked="row.item.single" :value=true :unchecked-value=false
              @change="onUpdate($event, row.item, 'single')">
            </b-form-checkbox>
          </template>
          <template #cell(bundle)="row">
            <b-form-checkbox :checked="row.item.bundle" :value=true :unchecked-value=false
              @change="onUpdate($event, row.item, 'bundle')">
            </b-form-checkbox>
          </template>
          <template #cell(subscription)="row">
            <b-form-checkbox :checked="row.item.subscription" :value=true :unchecked-value=false
              @change="onUpdate($event, row.item, 'subscription')">
            </b-form-checkbox>
          </template>
          <template #cell(buffet)="row">
            <b-form-checkbox :checked="row.item.buffet" :value=true :unchecked-value=false
              @change="onUpdate($event, row.item, 'buffet')">
            </b-form-checkbox>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="justify-content-start ml-1">
      <b-col lg="3">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState({
      items: (state) => state.vendorReportFormat.items,
      isLoading: (state) => state.vendorReportFormat.isLoading,
      isError: (state) => state.vendorReportFormat.isError,
      totalRows: (state) => state.vendorReportFormat.totalRows,
    }),
  },
  data() {
    return {
      vendorSearch: '',
      statusSearch: '',
      currentPage: 1,
      perPage: 50,
      fields: [
        { key: 'vendor_id' },
        { key: 'vendor_name' },
        { key: 'vendor_ax' },
        { key: 'is_active', label: 'Active' },
        { key: 'status', label: 'Reason' },
        { key: 'single' },
        { key: 'bundle' },
        { key: 'subscription' },
        { key: 'buffet' },
      ],
      isEditableStatus: undefined,
    };
  },
  methods: {
    ...mapActions('vendorReportFormat', ['fetchVendorFormat', 'updateVendorFormat']),
    async confirmChange(item, targetChange, changeValue) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-danger mx-1',
          cancelButton: 'btn btn-primary mx-1',
        },
        buttonsStyling: false,
      });
      const result = await swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: `${item.vendor_name} - ${targetChange} to ${changeValue}`,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
      });

      return result;
    },
    async onUpdate(changeValue, item, targetChange) {
      const { isConfirmed = false } = await this.confirmChange(item, targetChange, changeValue);
      if (!isConfirmed) return this.fetchVendorFormat();
      this.updateVendorFormat({
        [targetChange]: changeValue,
        id: item.id,
      }).then(() => {
      }).finally(() => {
        this.fetchVendorFormat({
          q: this.vendorSearch,
          page: this.currentPage,
          limit: this.perPage,
        });
      });
    },
    onSearch() {
      this.fetchVendorFormat({
        q: this.vendorSearch,
        status: this.statusSearch,
        page: this.currentPage,
        limit: this.perPage,
      });
    }
  },
  created() {
    this.fetchVendorFormat();
  },
  watch: {
    vendorSearch: function () {
      if (this.currentPage > 1) return this.currentPage = 1;
      this.onSearch();
    },
    statusSearch: function () {
      if (this.currentPage > 1) return this.currentPage = 1;
      this.onSearch();
    },
    currentPage: function () {
      this.onSearch();
    }
  },
}
</script>