var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', [_c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "Search by vendor name",
      "type": "search",
      "debounce": "500"
    },
    model: {
      value: _vm.vendorSearch,
      callback: function ($$v) {
        _vm.vendorSearch = $$v;
      },
      expression: "vendorSearch"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search by status",
      "type": "search",
      "debounce": "500"
    },
    model: {
      value: _vm.statusSearch,
      callback: function ($$v) {
        _vm.statusSearch = $$v;
      },
      expression: "statusSearch"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "p-1"
  }), _c('b-row', [_c('b-col', [_c('b-table', {
    attrs: {
      "sticky-header": "600px",
      "responsive": "xl",
      "busy": _vm.isLoading,
      "fields": _vm.fields,
      "items": _vm.items,
      "show-empty": "",
      "hover": "",
      "caption-top": ""
    },
    scopedSlots: _vm._u([{
      key: "head()",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(row.label) + " ")])];
      }
    }, {
      key: "table-caption",
      fn: function () {
        return [_c('div', {
          staticClass: "text-right"
        }, [_c('span', [_vm._v("Count: " + _vm._s(_vm.totalRows))])])];
      },
      proxy: true
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-primary my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "small": ""
          }
        }), _c('strong', [_vm._v("Loading...")])], 1)];
      },
      proxy: true
    }, {
      key: "cell(is_active)",
      fn: function (row) {
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": row.item.is_active,
            "value": true,
            "unchecked-value": false
          },
          on: {
            "change": function ($event) {
              return _vm.onUpdate($event, row.item, 'is_active');
            }
          }
        })];
      }
    }, {
      key: "cell(status)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-input', {
          attrs: {
            "value": row.item.status,
            "disabled": !(_vm.isEditableStatus == row.item.id)
          },
          on: {
            "change": function ($event) {
              return _vm.onUpdate($event, row.item, 'status');
            }
          }
        }), _c('b-button', {
          on: {
            "click": function ($event) {
              _vm.isEditableStatus = row.item.id;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        })])], 1)];
      }
    }, {
      key: "cell(single)",
      fn: function (row) {
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": row.item.single,
            "value": true,
            "unchecked-value": false
          },
          on: {
            "change": function ($event) {
              return _vm.onUpdate($event, row.item, 'single');
            }
          }
        })];
      }
    }, {
      key: "cell(bundle)",
      fn: function (row) {
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": row.item.bundle,
            "value": true,
            "unchecked-value": false
          },
          on: {
            "change": function ($event) {
              return _vm.onUpdate($event, row.item, 'bundle');
            }
          }
        })];
      }
    }, {
      key: "cell(subscription)",
      fn: function (row) {
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": row.item.subscription,
            "value": true,
            "unchecked-value": false
          },
          on: {
            "change": function ($event) {
              return _vm.onUpdate($event, row.item, 'subscription');
            }
          }
        })];
      }
    }, {
      key: "cell(buffet)",
      fn: function (row) {
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": row.item.buffet,
            "value": true,
            "unchecked-value": false
          },
          on: {
            "change": function ($event) {
              return _vm.onUpdate($event, row.item, 'buffet');
            }
          }
        })];
      }
    }])
  })], 1)], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }